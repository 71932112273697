<template>
  <div class="invoices-home">
    <div class="invoices-home__nav">
      <ul class="mobile-tabs__nav">
        <li>
          <router-link to="/invoices/allinvoice" class="routerDeco">
            <div
              class="mobile-tabs__title"
              :class="orderActiveTab === 0 ? 'mobile-tabs__title--active' : ''"
            >
              <!-- <span class="mobile-tabs__title__count">0</span> -->
              <span>All Invoices</span>
            </div></router-link
          >
        </li>
        <li>
          <router-link to="/invoices/pending" class="routerDeco">
            <div
              class="mobile-tabs__title"
              :class="orderActiveTab === 1 ? 'mobile-tabs__title--active' : ''"
            >
              <span>Pending</span>
            </div>
          </router-link>
        </li>
        <li>
          <router-link to="/invoices/paid" class="routerDeco">
            <div
              class="mobile-tabs__title"
              :class="orderActiveTab === 2 ? 'mobile-tabs__title--active' : ''"
            >
              <span>Paid</span>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <div
      v-if="invoices !== null"
      class="row rowmargininv  py-3 mt-3 text-center"
    >
      <div class="card amount-card rowmargininv-first-child">
        <div class="amount-icon">
          <img
            src="@/assets/static-assets/invoices-icon/total_earning.png"
            alt="Incoming Icon"
          />
        </div>
        <div class="amount-text">Total Earnings</div>
        <div v-if="regionCheckUSorOther" class="amount-text-value">
          $ {{ invoices[0].totals.baskytPayment }}
        </div>
        <div class="amount-text-value">
          PKR {{ invoices[0].totals.baskytPayment }}
        </div>
      </div>

      <div class="card amount-card">
        <div class="amount-icon">
          <img
            src="@/assets/static-assets/invoices-icon/total_Baskyt_payment.png"
            alt="Incoming Icon"
          />
        </div>
        <div class="amount-text">Total Baskyt Payment</div>
        <div v-if="regionCheckUSorOther" class="amount-text-value">
          $ {{ invoices[0].totals.baskytPayment }}
        </div>
        <div class="amount-text-value">
          PKR {{ invoices[0].totals.baskytPayment }}
        </div>
      </div>

      <div class="card amount-card rowmargininv-last-child">
        <div class="amount-icon">
          <img
            src="@/assets/static-assets/invoices-icon/total_orders_rece.png"
            alt="Incoming Icon"
          />
        </div>
        <div class="amount-text">Total Orders Recieved</div>
        <div class="amount-text-value">
          {{ invoices[0].performance.totalOrders }}
        </div>
      </div>
    </div>

    <b-tabs
      class="mobile-tabs"
      nav-class="d-none"
      content-class="border-red"
      v-model="orderActiveTab"
    >
      <b-tab active>
        <template v-if="orderActiveTab === 0">
          <AllInvoices ref="allInvoicesRef" />
        </template>
      </b-tab>
      <b-tab>
        <template v-if="orderActiveTab === 1">
          <Pending ref="pendinginvoicesRef" />
        </template>
      </b-tab>
      <b-tab>
        <template v-if="orderActiveTab === 2">
          <Paid ref="paidinvoicesRef" />
        </template>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AllInvoices from "@/view/sub-pages/invoices/AllInvoices";
import Pending from "@/view/sub-pages/invoices/Pending";
import Paid from "@/view/sub-pages/invoices/Paid";
import { GET_INVOICES } from "@/core/services/store/invoices.module";

export default {
  components: {
    AllInvoices,
    Pending,
    Paid
  },
  data() {
    return {
      orderActiveTab: 0,
      invoices: null
    };
  },
  async mounted() {
    const { id } = this.$route.params;
    if (id === "allinvoice") {
      this.orderActiveTab = 0;
    } else if (id === "pending") {
      this.orderActiveTab = 1;
    } else if (id === "paid") {
      this.orderActiveTab = 2;
    } else {
      this.orderActiveTab = 0;
    }
    const response = await this.$store.dispatch(GET_INVOICES);

    if (response.data.invoices !== undefined)
      this.invoices = response.data.invoices;
    console.log(
      "🚀 ~ file: Invoices.vue ~ line 153 ~ mounted ~ this.invoices",
      this.invoices
    );
  },
  methods: {
    // changeTab(tab) {
    //   $this.$route.params.mapState(`${tab}`);
    // }
  },
  watch: {
    $route(to) {
      const { id } = to.params;
      if (id === "allinvoice") {
        this.orderActiveTab = 0;
      } else if (id === "pending") {
        this.orderActiveTab = 1;
      } else if (id === "paid") {
        this.orderActiveTab = 2;
      } else {
        this.orderActiveTab = 0;
      }
    }
  },
  computed: {
    ...mapState({
      regionCheckUSorOther: state => state.regions.region
    })
  }
};
</script>

<style lang="scss" scoped></style>
