<template>
  <div id="pdf" class="invoice px-5 py-3">
    <div class="brand__image--top  mx-auto d-block">
      <img
        class="brand__image--img"
        src="@/assets/static-assets/icons/1. Final Logo Baskyt@3x.png"
        alt=""
      />
    </div>
    <div>
      <div class="row py-4 top-information">
        <div class="col  ">
          <div>
            <img
              class="  mx-auto d-block"
              src="@/assets/static-assets/invoices-icon/telephone@3x.png"
              width="22px"
            />
          </div>
          <div class="text-center pt-2 ">051-2375015</div>
        </div>
        <div class="col  ">
          <div>
            <img
              class="  mx-auto d-block"
              src="@/assets/static-assets/invoices-icon/home (2)@3x.png"
              width="22px"
            />
          </div>
          <div class="text-center pt-2">
            Office # 5, Ground Floor, Taha Arcade, E-11/3 Markaz, Islamabad
          </div>
        </div>
        <div class="col  ">
          <div>
            <img
              class="  mx-auto d-block"
              src="@/assets/static-assets/invoices-icon/globe@3x.png"
              width="22px"
            />
          </div>
          <div class="text-center pt-2">www.baskytinc.com</div>
        </div>
      </div>
      <div class="row pt-2 issue-information">
        <div class="col">
          <div>Issued date:</div>
          <div class="text-red mt-2">
            {{ new Date(this.invoiceDetails.createdAt).toDateString() }}
          </div>
        </div>
        <div class="col  text-center">
          <div class="  mx-auto d-block">
            <div class="text-red mt-2">
              {{ userName !== "" ? userName : "Partner name" }}
            </div>
          </div>
        </div>
        <div class="col  text-right">
          <div>Issued to:</div>
          <div class="text-red mt-2">
            {{ userName !== "" ? userName : "Partner name" }}
            ({{ this.invoiceDetails.duration }})
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-5">
      <div class="col summary__box mx-3 px-2 pt-2 pb-0 mb-0">
        <div class="text-center">Monthly Business Summary</div>
        <div class="row pt-3">
          <div class="col text-center">
            <div class="text-red summary__box--price  ">
              Rs {{ this.invoiceDetails.totals.amount.toLocaleString("en-IN") }}
            </div>
            <div class="text-muted-g h6 pt-2">Total Order Amount</div>
          </div>
          <div class="col text-center text-right">
            <div class="text-red summary__box--price">
              Rs
              {{
                this.invoiceDetails.totals.baskytPayment.toLocaleString("en-IN")
              }}
            </div>
            <div class="text-muted-g h6 pt-2">Total Baskyt Payment</div>
          </div>
        </div>
        <hr class="mt-0 hr" />

        <div class="row">
          <div class="col text-center pb-0 mb-0">
            <div class="summary__box--price">
              {{ this.invoiceDetails.totals.ordersInPrimary }}
            </div>
            <div class="text-muted-g h6">Total Primary Zone Order</div>
          </div>
          <div class="col text-center pb-0 mb-0">
            <div class="summary__box--price">
              {{ this.invoiceDetails.totals.ordersInSecondary }}
            </div>
            <div class="text-muted-g h6">Total Secondary Zone Order</div>
          </div>
          <div class="col text-center pb-0 mb-0">
            <div class="summary__box--price">
              {{ this.invoiceDetails.totals.ordersInOthers }}
            </div>
            <div class="text-muted-g h6">Total Others Zone Order</div>
          </div>
        </div>
      </div>
      <div class="col summary__box mx-3 p-2">
        <div class="text-center">Monthly Performance Summary</div>
        <div class="row pt-3">
          <div class="col text-center">
            <div class="text-red summary__box--price  ">
              {{ this.invoiceDetails.performance.totalOrders }}
            </div>
            <div class="text-muted-g h6 pt-2">Total Order Received</div>
          </div>
          <div class="col text-center text-right">
            <div class="text-red summary__box--price">
              {{ this.invoiceDetails.performance.deliveredOrders }}
            </div>
            <div class="text-muted-g h6 pt-2">Total Baskyt Delivered</div>
          </div>
        </div>
        <hr class="mt-0 hr" />
        <div class="row">
          <div class="col text-center">
            <div class="summary__box--price">
              {{ this.invoiceDetails.performance.cancelledOrders }}
            </div>
            <div class="text-muted-g h6 pt-2">Orders Cancelled</div>
          </div>
          <div class="col text-center">
            <div class="summary__box--price">
              {{ this.invoiceDetails.performance.ordersOnTime }}
            </div>
            <div class="text-muted-g h6 pt-2">
              Orders Delivered On Time
            </div>
          </div>
          <div class="col text-center">
            <div class="summary__box--price">
              {{ this.invoiceDetails.performance.ordersDelayed }}
            </div>
            <div class="text-muted-g h6 pt-2">Orders Delayed</div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-4">
      <!-- <div class="trapezoid_wrapper"><div class="trapezoid-top  "></div></div> -->
      <b-table
        hover
        :items="tableItems"
        :fields="tableFields"
        :striped="true"
        :responsive="true"
        thead-class="invoice__table__head shadow-sm"
        tbody-class="invoice__table__body"
      >
      </b-table>
      <!-- <div class="trapezoid_wrapper"><div class="trapezoid-bottom "></div></div> -->
    </div>
    <div class="html2pdf__page-break" />

    <div class="row payment-invoice ">
      <br />
      <div class=" bg-light payment-invoice-bank mx-auto">
        <h1 class="  py-3">Payment Details</h1>

        <h2 class="text-center">Bank</h2>
        <div class="row">
          <div class="col payment-invoice-red text-left mx-0">
            <div class="py-2 text-left">Account Title:</div>
            <div class="py-2 text-left">Account Number:</div>
            <div class="py-2 text-left">IBAN Number:</div>

            <div class="py-2 text-left">National Tax Number</div>
            <div class=" py-2 text-left">STRN</div>
          </div>
          <div class="col text-right mx-0">
            <div class="py-2 text-right">ISAAC’S CODE PRIVATE LIMITED</div>
            <div class="py-2 text-right">1231 7399 3100 1788</div>
            <div class="py-2 text-right">PK37 MUCB 1231 7399 3100 1788</div>
            <div class="py-2 text-right">5039398 – 1</div>
            <div class="py-2 text-right">3277876158264</div>
          </div>
          <div class="pt-2">
            Muslim Commercial Bank (MCB), E-11/3 Markaz, Islamabad
          </div>
        </div>
      </div>
      <div class=" bg-light payment-invoice-bank mx-auto">
        <h2 class="text-center my-5">Easypaisa</h2>
        <div class="row">
          <div class="col text-right payment-invoice-red">
            <div class="py-2 text-left">Account Title:</div>
            <div class="py-2 text-left">Account Number:</div>
          </div>
          <div class="col text-left ">
            <div class="py-2 text-left">Syed Arsalan Zaidi</div>
            <div class="py-2 text-left">0336-5410007</div>
          </div>
          <div class="col text-center">
            <img
              class="  mx-auto d-block"
              src="@/assets/static-assets/invoices-icon/easypesa.png"
              width="150px"
            />
          </div>
        </div>
      </div>

      <div class="text-center notice payment-invoice-bank mx-auto mt-3">
        In order to continue seamless services, it is requested to clear all
        payments within 3 days of receiving the invoice. Thank You!
      </div>
    </div>
  </div>
</template>

<script>
// import { jsPDF } from "jspdf";
import { getUserName, getUserAvatar } from "@/core/services/jwt.service";
// import pdf from 'vue-pdf'
export default {
  data() {
    return {
      userName: getUserName(),
      userImg: "",
      tableFields: [
        {
          key: "orderDate",
          label: "Date  ",
          sortable: true
        },
        {
          key: "primary",
          label: "Primary Zone Orders",
          sortable: true
        },
        {
          key: "secondary",
          label: "Secondary Zone Orders",
          sortable: true
        },
        {
          key: "other",
          label: "Other Zone Orders",
          sortable: true
        },
        {
          key: "total",
          label: "Total Orders  ",
          sortable: true
        },
        {
          key: "amount",
          label: "Total Order Amount",
          sortable: true
        },
        {
          key: "baskytPayment",
          label: "Baskyt Payments",
          sortable: true
        }
      ],
      tableItems: [],
      summaryFields: [
        {
          key: "totalOrders",
          label: "Total Orders Received  ",
          sortable: true
        },
        {
          key: "deliveredOrders",
          label: "Total Orders Delivered",
          sortable: true
        },
        {
          key: "cancelledOrders",
          label: "Total Orders Cancelled",
          sortable: true
        },
        {
          key: "ordersOnTime",
          label: "Orders Delivered On Time(45 mins)",
          sortable: true
        },
        {
          key: "ordersDelayed",
          label: "Orders Delayed(>45 mins)",
          sortable: true
        }
      ],
      summaryItems: [this.invoiceDetails.performance]
    };
  },
  props: {
    invoiceDetails: Object,
    status: String
  },
  mounted() {
    this.setOrders();
  },
  methods: {
    setOrders() {
      this.userImg = getUserAvatar();

      let prev = "";
      let orderData = {
        orderDate: "",
        other: 0,
        primary: 0,
        secondary: 0,
        total: 0,
        amount: 0,
        baskytPayment: 0
      };
      this.invoiceDetails.orders.forEach(data => {
        if (data.status === this.status) {
          if (prev === data.orderDate) {
            if (data.zone === "Others") orderData.other += 1;
            else if (data.zone === "Secondary") orderData.secondary += 1;
            else if (data.zone === "Primary") orderData.primary += 1;
            orderData.total += 1;
            orderData.amount += data.amount;
            orderData.baskytPayment += data.baskytPayment;
            prev = data.orderDate;
          } else if (prev !== "") {
            // orderData.orderDate = prev;
            prev = "";
            this.tableItems.unshift(orderData);
            orderData = {
              orderDate: "",
              other: 0,
              primary: 0,
              secondary: 0,
              total: 0,
              amount: 0,
              baskytPayment: 0
            };
          }
          if (prev === "") {
            if (data.zone === "Others") orderData.other += 1;
            else if (data.zone === "Secondary") orderData.secondary += 1;
            else if (data.zone === "Primary") orderData.primary += 1;
            orderData.total += 1;
            orderData.amount += data.amount;
            orderData.baskytPayment += data.baskytPayment;
            prev = data.orderDate;
            orderData.orderDate = prev;
          }
        }
      });
      this.tableItems.unshift(orderData);

      const total = {
        orderDate: "Grand Total",
        other: this.invoiceDetails.totals.ordersInOthers,
        primary: this.invoiceDetails.totals.ordersInPrimary,
        secondary: this.invoiceDetails.totals.ordersInSecondary,
        total: this.invoiceDetails.performance.deliveredOrders,
        amount: this.invoiceDetails.totals.amount,
        baskytPayment: this.invoiceDetails.totals.baskytPayment
      };
      this.tableItems.push(total);
    }
  }
};
</script>

<style lang="scss" scoped></style>
