<template>
  <div>
    <b-modal
      id="all-invoice-modal"
      modal-class="modal-class"
      size="sm"
      hide-header
      hide-footer
      no-close-on-backdrop
      centered
    >
      <!-- <div v-if="downloadPDF">
        <Pdfinvoice :invoiceDetails="invoiceDetails" />
      </div> -->
      <a class="btn close-btn" :disabled="loading" @click="hideAllInvoiceModal">
        <i class="fa fa-times"></i>
      </a>
      <template v-if="networkError !== null">
        <div class="row justify-content-center" style="color:red">
          {{ networkError }}
        </div>
      </template>
      <div class="p-3 ">
        <table class="px-2 testallinvtable tableallInvoicefontsize">
          <tr>
            <td class="tdsize text-left">
              <span>Date</span>
              <hr class="line" />
            </td>
            <td class="tdsize">
              <div class="invoice__circle">
                Invoice
              </div>
            </td>
            <td class="tdsize text-right">
              <span>{{ this.invoiceDetails.duration }}</span>
              <hr class="line" />
            </td>
          </tr>
          <tr class="py-2">
            <td class="tdsize text-left"><span>Invoice #</span></td>
            <td></td>
            <td class="tdsize text-right">
              <span>{{ this.invoiceDetails.invoiceId }}</span>
            </td>
          </tr>
          <tr class="py-2">
            <td class="tdsize text-left"><span>Primary Zone</span></td>
            <td></td>
            <td class="tdsize text-right">
              <span>{{ this.invoiceDetails.totals.ordersInPrimary }}</span>
            </td>
          </tr>
          <tr class="py-2">
            <td class="tdsize text-left"><span>Secondary Zone</span></td>
            <td></td>
            <td class="tdsize text-right">
              <span>{{ this.invoiceDetails.totals.ordersInSecondary }}</span>
            </td>
          </tr>
          <tr class="py-2">
            <td class="tdsize text-left"><span>Other Zones</span></td>
            <td></td>
            <td class="tdsize text-right">
              <span>{{ this.invoiceDetails.totals.ordersInOthers }}</span>
            </td>
          </tr>
          <tr class="redBpayment py-2">
            <td class="tdsize text-left"><span>Baskyt Payment</span></td>
            <td></td>
            <td v-if="regionCheckUSorOther" class="tdsize">
              <span>$ {{ this.invoiceDetails.totals.baskytPayment }}</span>
            </td>
            <td v-else class="tdsize text-right">
              <span>PKR {{ this.invoiceDetails.totals.baskytPayment }}</span>
            </td>
          </tr>
        </table>

        <table class="w-100 testallinvtable tableallInvoicefontsize mx-auto">
          <tr>
            <td>
              <div class="spanoftotall">
                <div>Total bill</div>
                <div>PKR {{ this.invoiceDetails.totals.baskytPayment }}</div>
              </div>
            </td>
          </tr>
        </table>

        <div class="pdfbuttonclassdiv">
          <button
            :disabled="downloading"
            :class="downloading ? 'pdfbutton--disabled' : 'pdfbutton--active'"
            class="pdfbutton"
            @click="downloadPDF"
          >
            <img
              class="imagedownloadicon"
              src="@/assets/static-assets/invoices-icon/download.svg"
              alt="Incoming Icon"
            />
            Download
            <div class="round-loader" v-if="downloading">
              <div class="round-loader__content round-loader__content--1"></div>
              <div class="round-loader__content round-loader__content--2"></div>
            </div>
          </button>

          <div hidden>
            <VueHtml2pdf
              :manual-pagination="true"
              :enable-download="true"
              ref="DownloadComp"
              @hasDownloaded="hasDownloaded($event)"
            >
              <section slot="pdf-content">
                <Pdfinvoice :invoiceDetails="invoiceDetails" :status="status" />
              </section>
            </VueHtml2pdf>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VueHtml2pdf from "vue-html2pdf";
import Pdfinvoice from "./PdfInvoice";

export default {
  data() {
    return {
      loading: false,
      downloadPdf: false,
      shown: false,
      networkError: null,
      downloading: false
    };
  },
  components: { Pdfinvoice, VueHtml2pdf },
  props: {
    invoiceDetails: Object,
    status: String
  },
  mounted() {
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      if (modalId === "all-invoice-modal") {
        this.shown = true;
      }
    });
  },
  methods: {
    showAllInvoiceModal() {
      this.$bvModal.show("all-invoice-modal");
    },
    hideAllInvoiceModal() {
      this.$bvModal.hide("all-invoice-modal");
      this.$emit("close");
    },
    hasDownloaded() {
      this.downloading = false;
      this.hideAllInvoiceModal();
    },
    downloadPDF() {
      console.log("running");
      this.generatePDF();
    },
    generatePDF() {
      this.downloading = true;
      this.$refs.DownloadComp.generatePdf();
    }
  },
  computed: {
    ...mapState({
      regionCheckUSorOther: state => state.regions.region
    })
  }
};
</script>

<style></style>
