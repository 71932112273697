<template>
  <div class="allinvoice-main">
    <div class="row rowmargininv pt-5">
      <div class="table">
        <template v-if="fetchingRecords">
          <loader />
        </template>
        <template v-if="networkError === null && tableItems.length > 0">
          <b-table
            hover
            :items="tableItems"
            :fields="tableFields"
            :busy="fetchingRecords"
            :responsive="true"
            :striped="true"
            thead-class="outgoing-payments-head shadow-sm"
            tbody-class="outgoing-payments-body"
            @row-clicked="orderRowClicked"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle mx-4"></b-spinner>
                <strong>Fetching...</strong>
              </div>
            </template>
            <template v-slot:cell(issued_date)="data">
              {{ new Date(data.value).toDateString() }}
            </template>
            <template v-slot:cell(order_no)="data">
              {{ data.value }}
            </template>
            <template v-slot:cell(totalBillofOrders)="data">
              Rs {{ data.value }}
            </template>
            <template v-slot:cell(baskytPayment)="data">
              Rs {{ data.value }}
            </template>
            <template v-slot:cell(customer_numbers)="data">
              {{ data.value }}
            </template>
            <template v-slot:cell(actions)="data">
              <button
                class="invoice__button"
                :ref="'btn' + data.index"
                @click="orderRowClicked"
              >
                Generate Invoice
              </button>
            </template>
            <template v-slot:cell(status)="data">
              <span
                v-if="data.value === 'Requested'"
                class="status status__requested"
              >
                {{ data.value }}
              </span>

              <span
                v-else-if="data.value === 'Pending'"
                class="status status__pending"
              >
                {{ data.value }}
              </span>

              <span
                v-else-if="data.value === 'Paid'"
                class="status status__verified"
              >
                {{ data.value }}
              </span>
            </template>
          </b-table>
        </template>
        <template v-else-if="networkError !== null">
          <div class="text-danger text-center display-4 p-4">API Error</div>
          <div class="text-danger text-center lead pb-4">
            Error while fetchting Invoices.
          </div>
          <div class="text-danger text-center lead pb-4">
            {{ networkError }}
          </div>
        </template>
        <template v-else-if="tableItems.length === 0 && !fetchingRecords">
          <div class="text-danger text-center display-4 p-4"></div>
          <div class="text-danger text-center lead pb-4">
            No Pending Invoices To Show.
          </div>
        </template>
      </div>
    </div>
    <template v-if="modalData !== null">
      <AllInvoiceModalPop
        ref="allInvoiceModal"
        :invoiceDetails="modalData"
        status="Pending"
        @close="allinvoiceModalClosed"
      />
    </template>
  </div>
</template>

<script>
import AllInvoiceModalPop from "@/view/sub-pages/invoices/AllInvoiceModalPop";
import { GET_INVOICES } from "@/core/services/store/invoices.module";
import BaskytProgress from "@/view/components/common/BaskytProgress";

export default {
  components: {
    AllInvoiceModalPop,
    loader: BaskytProgress
  },
  data() {
    return {
      na: "test-row",
      fetchingRecords: true,
      rows: 12,
      currentPage: 1,
      perPage: 10,
      invoiceData: [],
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" }
      ],
      networkError: null,
      tableFields: [
        {
          key: "invoiceNum",
          label: "Invoice #",
          sortable: true
        },
        {
          key: "issued_date",
          label: "Issued Date",
          sortable: true
        },
        {
          key: "totalNumOrders",
          label: "Total No. of Orders",
          sortable: true
        },
        {
          key: "totalBillofOrders",
          label: "Total Bill of Orders",
          sortable: true
        },
        {
          key: "baskytPayment",
          label: "Baskyt Payment",
          sortable: true
        },
        {
          key: "status",
          label: "Status",
          sortable: true
        },
        {
          key: "actions",
          label: "Actions",
          sortable: true
        }
      ],
      invoices: [],
      tableItems: [],
      modalData: [],
      dataInvoiceObj: {
        __v: 0,
        createdAt: "2021-07-07T12:02:25.189Z",
        updatedAt: "2021-07-07T12:02:25.189Z",
        duration: "June, 2021",
        _id: "60e597d0cc2a5b0de8046f15",
        performance: {
          ordersDelayed: 8,
          ordersOnTime: 4,
          cancelledOrders: 0,
          deliveredOrders: 12,
          totalOrders: 12
        },
        totals: {
          ordersInOthers: 6,
          ordersInSecondary: 6,
          ordersInPrimary: 0,
          amount: 35397,
          baskytPayment: 1740
        },
        store: {
          name: "District 6",
          store: "60acf846696911105d324ca6"
        },
        orders: [
          {
            order: "60b60d84ae58b181617f30b8",
            zone: "Secondary",
            cancelled: false,
            orderDate: "Tuesday, June 1st 2021",
            amount: 2577,
            baskytPayment: 125
          },
          {
            order: "60b9e7903e72108ab3d7136f",
            zone: "Secondary",
            cancelled: false,
            orderDate: "Friday, June 4th 2021",
            amount: 3757,
            baskytPayment: 125
          },
          {
            order: "60ba09bc3e72108ab3d7333d",
            zone: "Others",
            cancelled: false,
            orderDate: "Friday, June 4th 2021",
            amount: 2940,
            baskytPayment: 165
          },
          {
            order: "60c08a82976e49c562b33a36",
            zone: "Others",
            cancelled: false,
            orderDate: "Wednesday, June 9th 2021",
            amount: 2440,
            baskytPayment: 165
          },
          {
            order: "60c1db0c53083caf2ee1cf4f",
            zone: "Secondary",
            cancelled: false,
            orderDate: "Thursday, June 10th 2021",
            amount: 2931,
            baskytPayment: 125
          },
          {
            order: "60c33691e32214c63033dde0",
            zone: "Others",
            cancelled: false,
            orderDate: "Friday, June 11th 2021",
            amount: 3407,
            baskytPayment: 165
          },
          {
            order: "60c35cc2bb0ccf05c122c609",
            zone: "Secondary",
            cancelled: false,
            orderDate: "Friday, June 11th 2021",
            amount: 4826,
            baskytPayment: 125
          },
          {
            order: "60c4ba59bb0ccf05c123129a",
            zone: "Others",
            cancelled: false,
            orderDate: "Saturday, June 12th 2021",
            amount: 2547,
            baskytPayment: 165
          },
          {
            order: "60c9d3b83b12b4173864920f",
            zone: "Others",
            cancelled: false,
            orderDate: "Wednesday, June 16th 2021",
            amount: 3085,
            baskytPayment: 165
          },
          {
            order: "60cce1943532b4f6df7448c9",
            zone: "Others",
            cancelled: false,
            orderDate: "Friday, June 18th 2021",
            amount: 10,
            baskytPayment: 165
          },
          {
            order: "60cece3935a8ca53366c8909",
            zone: "Secondary",
            cancelled: false,
            orderDate: "Sunday, June 20th 2021",
            amount: 4300,
            baskytPayment: 125
          },
          {
            order: "60d717286366c22b1a75736b",
            zone: "Secondary",
            cancelled: false,
            orderDate: "Saturday, June 26th 2021",
            amount: 2577,
            baskytPayment: 125
          }
        ],
        type: "payment"
      }
    };
  },
  async mounted() {
    const response = await this.$store.dispatch(GET_INVOICES);
    this.invoices = response.data.invoices;
    console.log(
      "🚀 ~ file: AllInvoices.vue ~ line 318 ~ mounted ~ this.invoices",
      this.invoices
    );
    this.setData();
  },
  methods: {
    async changePage(val) {
      this.currentPage = val;
    },
    setData() {
      this.fetchingRecords = true;
      this.invoices.forEach(data => {
        const temp = [];
        if (data.status === "Pending") {
          temp.invoiceNum = data.invoiceId;
          temp.issued_date = data.createdAt;
          temp.totalNumOrders = data.performance.totalOrders;
          temp.totalBillofOrders = data.totals.amount;
          temp.baskytPayment = data.totals.baskytPayment;
          temp.status = data.status;
          this.tableItems.push(temp);
        }
      });
      this.fetchingRecords = false;
    },
    async perPageChange(val) {
      await this.updateOrders();
      this.perPage = val;
    },
    orderRowClicked(item) {
      this.invoices.forEach(data => {
        if (item.invoiceNum === data.invoiceId) {
          this.modalData = data;
        }
      });
      setTimeout(() => {
        this.$refs.allInvoiceModal.showAllInvoiceModal();
      }, 100);
    },
    allinvoiceModalClosed() {
      this.modalData = null;
    }
  }
};
</script>

<style></style>
